import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useFetch } from 'hooks/util/useFetch';
import { ApiError } from 'models/api/error';
import { Nomination, UpdateNominationRequest } from 'models/api/nomination';

export const useUpdateNomination = (id?: string) => {
  const [$fetch] = useFetch<Nomination>();
  const queryClient = useQueryClient();

  return useMutation<Nomination, ApiError, UpdateNominationRequest>(
    (values) =>
      $fetch(`/api/nominations/${id as string}`, {
        body: values,
        method: 'POST',
      }),
    {
      onError: (error) => {
        toast.error(error.data.message || 'An unexpected error occurred.');
      },
      onSuccess: async (data) => {
        queryClient.setQueryData(['nominations', id], data);
        await queryClient.invalidateQueries(['nominations'], { exact: true });
      },
    },
  );
};
