import * as Switch from '@radix-ui/react-switch';
import classNames from 'classnames';
import { useTheme } from 'hooks/util/useTheme';
import styles from './ThemeToggle.module.css';

interface ThemeToggleProps {
  className?: string;
}

export const ThemeToggle = (props: ThemeToggleProps) => {
  const { className } = props;
  const { isDarkMode, toggleTheme } = useTheme();

  return (
    <Switch.Root
      checked={isDarkMode}
      className={classNames(styles.root, className)}
      onCheckedChange={toggleTheme}
    >
      <Switch.Thumb className={styles.thumb} />
    </Switch.Root>
  );
};
