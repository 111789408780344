/* eslint-disable @typescript-eslint/no-explicit-any */
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Column, useTable } from 'react-table';
import styles from './Table.module.css';

interface TableProps {
  columns?: Column<any>[];
  data?: any[];
  isLoading?: boolean;
  loadingMessage?: string;
  noDataMessage?: string;
}

export const Table = (props: TableProps) => {
  const {
    columns = [],
    data = [],
    isLoading,
    loadingMessage = 'Loading',
    noDataMessage = 'No Data',
  } = props;
  const { getTableProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data,
  });

  return (
    <div className={styles.container}>
      <table {...getTableProps()} className={styles.table}>
        <thead className={styles.head}>
          {headerGroups.map((headerGroup, i) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className={styles.row}
              key={i}
            >
              {headerGroup.headers.map((header, j) => (
                <th
                  {...header.getHeaderProps()}
                  className={styles.cell}
                  key={j}
                >
                  {header.render('Header')}
                  <span
                    className={classNames(styles.sort, {
                      [styles.hidden]: !header.isSorted,
                    })}
                  >
                    <FontAwesomeIcon
                      icon={header.isSortedDesc ? faCaretUp : faCaretDown}
                    />
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className={styles.body}>
          {rows.length > 0 ? (
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className={styles.row} key={i}>
                  {row.cells.map((cell, j) => (
                    <td
                      {...cell.getCellProps()}
                      className={styles.cell}
                      key={j}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })
          ) : (
            <tr className={styles.row}>
              <td
                className={classNames(styles.cell, styles['no-data'])}
                colSpan={columns.length}
              >
                {isLoading ? loadingMessage : noDataMessage}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
