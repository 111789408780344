import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useFetch } from 'hooks/util/useFetch';
import { User } from 'models/api/user';
import { ApiError } from 'models/api/error';

export const useUser = (id?: string) => {
  const [$fetch] = useFetch<User>();
  return useQuery<User, ApiError>(
    ['users', id],
    () => $fetch(`/api/users/${id as string}`),
    {
      enabled: !!id,
      onError: (error) => {
        toast.error(error.data.message || 'An unexpected error occurred.');
      },
    },
  );
};
