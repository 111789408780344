import classNames from 'classnames';
import {
  ChangeEvent,
  FocusEvent,
  HTMLProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styles from './TextField.module.css';

export enum TextFieldVariants {
  Currency = 'currency',
  Zip = 'zip',
}

interface TextFieldProps extends HTMLProps<HTMLInputElement> {
  defaultValue?: number | string;
  error?: string;
  label?: string;
  value?: number | string;
  variant?: TextFieldVariants;
}

export const TextField = (props: TextFieldProps) => {
  const {
    defaultValue,
    error,
    label,
    onBlur,
    onChange,
    onFocus,
    pattern,
    type,
    value: controlledValue,
    variant,
    ...inputProps
  } = props;
  const [value, setValue] = useState<number | string>(
    controlledValue || defaultValue || '',
  );
  const [isFilled, setIsFilled] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur?.(e);
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus?.(e);
  };

  const handleVariantChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;

      switch (variant) {
        case TextFieldVariants.Currency: {
          // Ensure only digits and add decimal.
          value = value.replace(/\D/g, '').replace(/(\d)(\d{2})$/, '$1.$2');
          e.target.value = value;
          break;
        }
      }

      setValue(value);
      onChange?.(e);
    },
    [onChange, variant],
  );

  const variantPattern = useMemo(() => {
    switch (variant) {
      case TextFieldVariants.Currency:
        return '[0-9]*';
    }
    return pattern;
  }, [pattern, variant]);

  const variantType = useMemo(() => {
    switch (variant) {
      case TextFieldVariants.Currency:
        return 'text';
    }
    return type;
  }, [type, variant]);

  const variantValue = useMemo(() => {
    const result = String(value);

    switch (variant) {
      case TextFieldVariants.Currency:
        return `$${result.replace(/(?=(\d{3})+(\D))\B/g, ',')}`;
    }

    return result;
  }, [value, variant]);

  useEffect(() => {
    setIsFilled(String(value).length > 0);
  }, [value]);

  useEffect(() => {
    if (controlledValue !== undefined) {
      setValue(controlledValue);
    }
  }, [controlledValue]);

  return (
    <div
      className={classNames(styles.container, {
        [styles.errored]: !!error,
        [styles.filled]: isFilled,
        [styles.focused]: isFocused,
      })}
    >
      {label && (
        <label className={styles.label}>
          {label}
          {props.required && '*'}
        </label>
      )}
      <input
        {...inputProps}
        className={styles.input}
        onBlur={handleBlur}
        onChange={handleVariantChange}
        onFocus={handleFocus}
        pattern={variantPattern}
        type={variantType}
        value={variantValue}
      />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};
