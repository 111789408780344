import { useEffect, useState } from 'react';

interface SpinnerProps {
  className?: string;
}

export const Spinner = (props: SpinnerProps) => {
  const { className } = props;
  const [percent, setPercent] = useState(0);
  const size = 60;
  const stroke = 4;
  const radius = size / 2 - stroke * 2;
  const circumference = radius * 2 * Math.PI;

  useEffect(() => {
    let timer: NodeJS.Timeout;

    async function tick() {
      await new Promise((resolve) => {
        timer = setTimeout(resolve, 25);
      });
      if (percent < 100) setPercent(percent + 1);
      if (percent === 100) setPercent(0);
    }

    tick().catch((error) => {
      console.error(error);
    });

    return () => {
      clearTimeout(timer);
    };
  }, [percent]);

  return (
    <svg className={className} height={size} width={size}>
      <circle
        r={radius}
        cx={size / 2}
        cy={size / 2}
        stroke="#63cae0"
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={circumference - (percent / 100) * circumference}
        style={{
          transform: 'rotate(-90deg)',
          transformOrigin: '50% 50%',
        }}
      />
    </svg>
  );
};
