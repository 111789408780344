import { useMutation, useQueryClient } from 'react-query';
import { useFetch } from 'hooks/util/useFetch';

export const useAuthLogout = () => {
  const [$fetch] = useFetch();
  const queryClient = useQueryClient();
  return useMutation(() => $fetch('/api/auth/logout', { method: 'DELETE' }), {
    onSuccess: () => {
      queryClient.setQueryData(['auth'], undefined);
    },
  });
};
