import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ChangeEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonVariants } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Select } from 'components/Select';
import { TextField } from 'components/TextField';
import { useCauses } from 'hooks/query/useCauses';
import { useDonation } from 'hooks/query/useDonation';
import { useBreakpoints } from 'hooks/util/useBreakpoints';
import { useQueryParam } from 'hooks/util/useQueryParam';
import { getAvailableAmount } from 'utils/donation';
import { formatAmount } from 'utils/format';
import { List } from './List';
import styles from './Nominations.module.css';

export const AdminNominations = () => {
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [isShowingAccepted, setIsShowingAccepted] = useState<boolean>(true);
  const [isShowingCompleted, setIsShowingCompleted] = useState<boolean>(false);
  const [isShowingPending, setIsShowingPending] = useState<boolean>(true);
  const [isShowingRejected, setIsShowingRejected] = useState<boolean>(false);
  const [filteredCause, setFilteredCause] = useState<string>('');
  const [filteredId, setFilteredId] = useState<string>('');
  const [filteredNominee, setFilteredNominee] = useState<string>('');
  const donationId = useQueryParam('donationId');
  const { isMobile } = useBreakpoints();
  const { data: causes } = useCauses();
  const { data: donation } = useDonation(donationId);

  useEffect(() => {
    setIsFilterOpen(!isMobile);
  }, [isMobile]);

  return (
    <Section>
      <Container>
        <header className={styles.header}>
          <h1>Manage Nominations</h1>
          {isMobile &&
            (isFilterOpen ? (
              <Button
                key="close"
                onClick={() => setIsFilterOpen(false)}
                variant={ButtonVariants.Secondary}
              >
                Close Filter
              </Button>
            ) : (
              <Button
                key="filter"
                onClick={() => setIsFilterOpen(true)}
                variant={ButtonVariants.Secondary}
              >
                Filter
              </Button>
            ))}
          {isFilterOpen && (
            <div className={styles.filters}>
              <div className={styles['filter-row']}>
                <Checkbox
                  checked={isShowingPending}
                  label="Show Pending"
                  onCheckedChange={(checked) => setIsShowingPending(checked)}
                />
                <Checkbox
                  checked={isShowingAccepted}
                  label="Show Accepted"
                  onCheckedChange={(checked) => setIsShowingAccepted(checked)}
                />
                <Checkbox
                  checked={isShowingRejected}
                  label="Show Rejected"
                  onCheckedChange={(checked) => setIsShowingRejected(checked)}
                />
                <Checkbox
                  checked={isShowingCompleted}
                  label="Show Completed"
                  onCheckedChange={(checked) => setIsShowingCompleted(checked)}
                />
              </div>
              <div className={styles['filter-row']}>
                <TextField
                  label="Nominee"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilteredNominee(e.target.value)
                  }
                  value={filteredNominee}
                />
                <Select
                  label="Cause"
                  options={causes?.map((cause) => ({
                    label: cause.name,
                    value: cause.id,
                  }))}
                  onChange={(cause) => setFilteredCause(cause?.value || '')}
                  value={filteredCause}
                />
                <TextField
                  label="ID"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilteredId(e.target.value)
                  }
                  value={filteredId}
                />
              </div>
            </div>
          )}
        </header>
        {donation && (
          <div className={styles.allocating}>
            <p>
              Allocating funds from{' '}
              <strong>{formatAmount(getAvailableAmount(donation))}</strong> |
              Cause: <strong>{donation.cause?.name}</strong>
            </p>
            <Link to="/admin/nominations">
              <FontAwesomeIcon icon={faTimes} />
            </Link>
          </div>
        )}
        <List
          donation={donation}
          filteredCause={filteredCause}
          filteredId={filteredId}
          filteredNominee={filteredNominee}
          showAccepted={isShowingAccepted}
          showCompleted={isShowingCompleted}
          showPending={isShowingPending}
          showRejected={isShowingRejected}
        />
      </Container>
    </Section>
  );
};
