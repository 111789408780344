import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useFetch } from 'hooks/util/useFetch';
import {
  CreateContactRequest,
  CreateContactResponse,
} from 'models/api/contact';
import { ApiError } from 'models/api/error';

export const useCreateContact = () => {
  const [$fetch] = useFetch<CreateContactResponse>();
  return useMutation<CreateContactResponse, ApiError, CreateContactRequest>(
    (values) =>
      $fetch('/api/contact', {
        body: values,
        method: 'POST',
      }),
    {
      onError: (error) => {
        toast.error(error.data.message || 'An unexpected error occurred.');
      },
      onSuccess: (data) => {
        toast.success(data.message);
      },
    },
  );
};
