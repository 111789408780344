import { Link } from 'react-router-dom';
import { Container } from 'components/Container';
import styles from './Nav.module.css';

export const Nav = () => {
  return (
    <nav className={styles.nav}>
      <Container className={styles.container}>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/donate">Donate</Link>
          </li>
          <li>
            <Link to="/nominate">Nominate</Link>
          </li>
        </ul>
      </Container>
    </nav>
  );
};
