import { useFormik } from 'formik';
import { Button, ButtonVariants } from 'components/Button';
import { TextField } from 'components/TextField';
import { useCreateCause } from 'hooks/mutation/useCreateCause';
import styles from './Form.module.css';
import { validationSchema } from './validationSchema';
import { useState } from 'react';

export const Form = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { mutateAsync } = useCreateCause();
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: async (values, { resetForm }) => {
      await mutateAsync(values);
      resetForm();
    },
    validateOnChange: false,
    validationSchema,
  });

  return (
    <div className={styles.container}>
      {!isOpen ? (
        <Button
          className={styles.button}
          onClick={() => setIsOpen(true)}
          variant={ButtonVariants.Secondary}
        >
          Add Cause
        </Button>
      ) : (
        <form className={styles.form} noValidate onSubmit={formik.handleSubmit}>
          <TextField
            error={formik.errors.name}
            label="Name"
            name="name"
            onChange={formik.handleChange}
            required
            value={formik.values.name}
          />
          <Button type="submit">Add Cause</Button>
          <Button
            onClick={() => setIsOpen(false)}
            variant={ButtonVariants.Secondary}
          >
            Cancel
          </Button>
        </form>
      )}
    </div>
  );
};
