import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import styles from './Container.module.css';

export enum ContainerSizes {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

interface ContainerProps {
  as?: keyof JSX.IntrinsicElements;
  className?: string;
  size?: ContainerSizes;
}

export const Container = (props: PropsWithChildren<ContainerProps>) => {
  const { as, children, className, size = ContainerSizes.Large } = props;
  const Tag = as || 'div';

  return (
    <Tag className={classNames(styles.container, styles[size], className)}>
      {children}
    </Tag>
  );
};
