import styles from './Mission.module.css';

export const Mission = () => {
  return (
    <div className={styles.mission}>
      <h2>Our Mission</h2>
      {/* <p>Have you every wondered where your money goes when you donate it? The impact it has on someone’s life? Now you can know the truth!</p> */}
      <p>
        We are a charity that believes in complete transparency. When you donate
        and your money is used you will receive a receipt of how your donation
        was spent. Photos and or video of the experience from beginning to end.
        Social media access too.
      </p>
      <p>
        No more guessing or wondering. 100% of your donation going to its
        intended purpose and you see it{' '}
        <span>
          SEEIT<strong>MOVE</strong>
        </span>{' '}
        the whole time!
      </p>
    </div>
  );
};
