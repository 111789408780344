import { Helmet } from 'react-helmet';
import { Container, ContainerSizes } from 'components/Container';
import { Section } from 'components/Section';
import { Form } from './Form';
import { Hero } from 'components/Hero';

export const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      <Hero>
        <h1>Contact Us</h1>
        <p>
          Have an issue, question, or comment? Fill out the form below and a
          member of our team will reach out to you shortly.
        </p>
      </Hero>
      <Section>
        <Container size={ContainerSizes.Medium}>
          <Form />
        </Container>
      </Section>
    </>
  );
};
