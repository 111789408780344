import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useFetch } from 'hooks/util/useFetch';
import { ApiError } from 'models/api/error';
import { CreateUserRequest, User } from 'models/api/user';

export const useCreateUser = () => {
  const [$fetch] = useFetch<User>();
  const queryClient = useQueryClient();
  return useMutation<User, ApiError, CreateUserRequest>(
    (values) =>
      $fetch('/api/users', {
        body: values,
        method: 'POST',
      }),
    {
      onError: (error) => {
        toast.error(error.data.message || 'An unexpected error occurred.');
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['users']);
      },
    },
  );
};
