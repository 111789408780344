import classNames from 'classnames';
import { ChangeEvent, FocusEvent, HTMLProps, useEffect, useState } from 'react';
import styles from 'components/TextField/TextField.module.css';
import textAreaStyles from './TextArea.module.css';

interface TextAreaProps extends HTMLProps<HTMLTextAreaElement> {
  error?: string;
  label?: string;
}

export const TextArea = (props: TextAreaProps) => {
  const { error, label, onBlur, onChange, onFocus, ...inputProps } = props;
  const [value, setValue] = useState<string>('');
  const [isFilled, setIsFilled] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(false);
    onBlur?.(e);
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    onChange?.(e);
  };

  const handleFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(true);
    onFocus?.(e);
  };

  useEffect(() => {
    setIsFilled(value.length > 0);
  }, [value]);

  return (
    <div
      className={classNames(styles.container, textAreaStyles.container, {
        [styles.errored]: !!error,
        [styles.filled]: isFilled,
        [styles.focused]: isFocused,
      })}
    >
      {label && (
        <label className={styles.label}>
          {label}
          {props.required && '*'}
        </label>
      )}
      <textarea
        {...inputProps}
        className={styles.input}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
      />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};
