import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useFetch } from 'hooks/util/useFetch';
import { CreateCauseRequest, Cause } from 'models/api/cause';
import { ApiError } from 'models/api/error';

export const useCreateCause = () => {
  const [$fetch] = useFetch<Cause>();
  const queryClient = useQueryClient();
  return useMutation<Cause, ApiError, CreateCauseRequest>(
    (cause: CreateCauseRequest) =>
      $fetch('/api/causes', {
        body: cause,
        method: 'POST',
      }),
    {
      onError: (error) => {
        toast.error(error.data.message || 'An unexpected error occurred.');
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['causes']);
      },
    },
  );
};
