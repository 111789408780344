import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import classNames from 'classnames';
import styles from './Checkbox.module.css';

interface CheckboxProps {
  disabled?: boolean;
  checked?: boolean;
  label?: string;
  name?: string;
  onCheckedChange?: (checked: boolean) => void;
}

export const Checkbox = (props: CheckboxProps) => {
  const { checked, disabled, label, name, onCheckedChange } = props;

  return (
    <div
      className={classNames(styles.container, { [styles.disabled]: disabled })}
    >
      <CheckboxPrimitive.Root
        checked={checked}
        className={styles.root}
        disabled={disabled}
        name={name}
        onCheckedChange={onCheckedChange}
      >
        <CheckboxPrimitive.Indicator className={styles.indicator}>
          <FontAwesomeIcon icon={faCheck} />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
      {label && <label>{label}</label>}
    </div>
  );
};
