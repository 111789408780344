import { useFormik } from 'formik';
import { ReactComponent as StripeImage } from 'assets/images/stripe.svg';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { Select } from 'components/Select';
import { TextArea } from 'components/TextArea';
import { TextField, TextFieldVariants } from 'components/TextField';
import { useCreateDonation } from 'hooks/mutation/useCreateDonation';
import { useCauses } from 'hooks/query/useCauses';
import { validationSchema } from './validationSchema';
import styles from './Form.module.css';

const isDisabled = process.env.REACT_APP_DONATIONS_DISABLED === 'true';

export const Form = () => {
  const { data: causes } = useCauses();
  const { mutateAsync: createDonation } = useCreateDonation();
  const formik = useFormik({
    initialValues: {
      amount: '',
      causeId: '',
      description: '',
      firstName: '',
      isAvailableToVolunteerTime: false,
      lastName: '',
      email: '',
      phone: '',
      zip: '',
    },
    onSubmit: async (values) => {
      const session = await createDonation({
        ...values,
        amount: Number(values.amount),
      });
      window.location.href = session.url;
    },
    validateOnChange: false,
    validationSchema,
  });

  return (
    <>
      {isDisabled && (
        <p className={styles.disabled}>
          We're sorry, but we are not accepting donations at this time. Please
          check back later.
        </p>
      )}
      <form className={styles.form} onSubmit={formik.handleSubmit} noValidate>
        <fieldset>
          <legend>Donation Information</legend>
          <div className={styles.amount}>
            <TextField
              disabled={isDisabled}
              error={formik.errors.amount}
              label="Amount"
              name="amount"
              onChange={formik.handleChange}
              required
              type="number"
              value={formik.values.amount}
              variant={TextFieldVariants.Currency}
            />
            <Select
              disabled={isDisabled}
              error={formik.errors.causeId}
              label="Cause"
              onChange={(option) =>
                formik.setFieldValue('causeId', option?.value)
              }
              options={causes?.map((cause) => ({
                label: cause.name,
                value: cause.id,
              }))}
              required
              value={formik.values.causeId}
            />
          </div>
          <TextArea
            disabled={isDisabled}
            error={formik.errors.description}
            label="Description"
            name="description"
            onChange={formik.handleChange}
            value={formik.values.description}
          />
          <Checkbox
            checked={formik.values.isAvailableToVolunteerTime}
            disabled={isDisabled}
            label="I am available to volunteer my time"
            name="isAvailableToVolunteerTime"
            onCheckedChange={async (checked) =>
              formik.setFieldValue('isAvailableToVolunteerTime', checked)
            }
          />
        </fieldset>
        <fieldset>
          <legend>Your Information</legend>
          <TextField
            disabled={isDisabled}
            error={formik.errors.firstName}
            label="First Name"
            name="firstName"
            onChange={formik.handleChange}
            required
            value={formik.values.firstName}
          />
          <TextField
            disabled={isDisabled}
            error={formik.errors.lastName}
            label="Last Name"
            name="lastName"
            onChange={formik.handleChange}
            required
            value={formik.values.lastName}
          />
          <TextField
            disabled={isDisabled}
            error={formik.errors.email}
            label="Email Address"
            name="email"
            onChange={formik.handleChange}
            required
            type="email"
            value={formik.values.email}
          />
          <TextField
            disabled={isDisabled}
            error={formik.errors.phone}
            label="Phone Number"
            name="phone"
            onChange={formik.handleChange}
            required
            value={formik.values.phone}
          />
          <TextField
            disabled={isDisabled}
            error={formik.errors.zip}
            label="Zip Code"
            name="zip"
            onChange={formik.handleChange}
            required
            value={formik.values.zip}
          />
        </fieldset>
        <Button disabled={isDisabled} type="submit">
          Continue to Payment Information
        </Button>
        <a
          className={styles.stripe}
          href="https://www.stripe.com"
          rel="noreferrer"
          target="_blank"
        >
          <StripeImage />
        </a>
      </form>
    </>
  );
};
