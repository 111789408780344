import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/Button';
import { Container, ContainerSizes } from 'components/Container';
import { Section } from 'components/Section';
import { Select } from 'components/Select';
import { TextArea } from 'components/TextArea';
import { TextField } from 'components/TextField';
import { useCreateNomination } from 'hooks/mutation/useCreateNomination';
import { useCauses } from 'hooks/query/useCauses';
import { validationSchema } from './validationSchema';
import styles from './Form.module.css';

const isDisabled = process.env.REACT_APP_NOMINATIONS_DISABLED === 'true';

export const Form = () => {
  const navigate = useNavigate();
  const { data: causes } = useCauses();
  const { mutateAsync } = useCreateNomination();
  const formik = useFormik({
    initialValues: {
      nominee: '',
      causeId: '',
      description: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      zip: '',
    },
    onSubmit: async (values, { resetForm }) => {
      await mutateAsync(values);
      resetForm();
      navigate('/nominate?status=success');
    },
    validateOnChange: false,
    validationSchema,
  });

  return (
    <Section>
      <Container className={styles.container} size={ContainerSizes.Small}>
        {isDisabled && (
          <p className={styles.disabled}>
            We're sorry, but we are not accepting nominations at this time.
            Please check back later.
          </p>
        )}
        <form className={styles.form} onSubmit={formik.handleSubmit} noValidate>
          <fieldset>
            <legend>Nomination Information</legend>
            <TextField
              disabled={isDisabled}
              error={formik.errors.nominee}
              label="Name of Nominee"
              name="nominee"
              onChange={formik.handleChange}
              required
              value={formik.values.nominee}
            />
            <Select
              disabled={isDisabled}
              error={formik.errors.causeId}
              label="Cause"
              onChange={(option) =>
                formik.setFieldValue('causeId', option?.value)
              }
              options={causes?.map((cause) => ({
                label: cause.name,
                value: cause.id,
              }))}
              required
              value={formik.values.causeId}
            />
            <TextArea
              disabled={isDisabled}
              error={formik.errors.description}
              label="Description"
              name="description"
              onChange={formik.handleChange}
              required
              value={formik.values.description}
            />
          </fieldset>
          <fieldset>
            <legend>Your Information</legend>
            <TextField
              disabled={isDisabled}
              error={formik.errors.firstName}
              label="First Name"
              name="firstName"
              onChange={formik.handleChange}
              required
              value={formik.values.firstName}
            />
            <TextField
              disabled={isDisabled}
              error={formik.errors.lastName}
              label="Last Name"
              name="lastName"
              onChange={formik.handleChange}
              required
              value={formik.values.lastName}
            />
            <TextField
              disabled={isDisabled}
              error={formik.errors.email}
              label="Email Address"
              name="email"
              onChange={formik.handleChange}
              required
              type="email"
              value={formik.values.email}
            />
            <TextField
              disabled={isDisabled}
              error={formik.errors.phone}
              label="Phone Number"
              name="phone"
              onChange={formik.handleChange}
              required
              value={formik.values.phone}
            />
            <TextField
              disabled={isDisabled}
              error={formik.errors.zip}
              label="Zip Code"
              name="zip"
              onChange={formik.handleChange}
              required
              value={formik.values.zip}
            />
          </fieldset>
          <Button disabled={isDisabled} type="submit">
            Submit Nomination
          </Button>
        </form>
      </Container>
    </Section>
  );
};
