import { faEdit, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import { Table } from 'components/Table';
import { useDonations } from 'hooks/query/useDonations';
import { Donation } from 'models/api/donation';
import { Nomination } from 'models/api/nomination';
import { getAvailableAmount } from 'utils/donation';
import { formatAmount, formatDate } from 'utils/format';
import { DetailModal } from '../DetailModal';
import { AllocationModal } from '../AllocationModal';
import styles from './List.module.css';

interface ListProps {
  filteredId?: string;
  nomination?: Nomination;
  showFullyAllocated?: boolean;
}

export const List = (props: ListProps) => {
  const { filteredId, nomination, showFullyAllocated } = props;
  const [detailId, setDetailId] = useState<string>();
  const [allocationId, setAllocationId] = useState<string>();
  const { data: donations, isLoading } = useDonations();

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: (donation: Donation) => formatDate(donation.createdAt),
        id: 'date',
      },
      {
        Header: 'Amount',
        accessor: (donation: Donation) => formatAmount(donation.amount),
        id: 'amount',
      },
      {
        Header: 'Available Amount',
        accessor: (donation: Donation) =>
          formatAmount(getAvailableAmount(donation)),
      },
      {
        Header: 'Cause',
        accessor: (donation: Donation) => donation.cause?.name,
        id: 'cause',
      },
      {
        Header: '',
        accessor: (donation: Donation) => (
          <div className={styles.actions}>
            {nomination && (
              <button
                className={styles.button}
                onClick={() => setAllocationId(donation.id)}
                type="button"
              >
                <FontAwesomeIcon icon={faFileInvoiceDollar} />
              </button>
            )}
            <button
              className={styles.button}
              onClick={() => setDetailId(donation.id)}
              type="button"
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
          </div>
        ),
        id: 'actions',
        disableSortBy: true,
      },
    ],
    [nomination],
  );

  const filteredDonations = useMemo(() => {
    let filtered = donations || [];

    if (filteredId)
      filtered = filtered.filter((donation) => donation.id === filteredId);

    if (!showFullyAllocated)
      filtered = filtered.filter(
        (donation) => getAvailableAmount(donation) > 0,
      );

    return filtered;
  }, [donations, filteredId, showFullyAllocated]);

  return (
    <>
      <Table
        columns={columns}
        data={filteredDonations}
        isLoading={isLoading}
        loadingMessage="Loading donations."
        noDataMessage="There are no donations."
      />
      <AllocationModal
        id={allocationId}
        nomination={nomination}
        onClose={() => setAllocationId(undefined)}
      />
      <DetailModal id={detailId} onClose={() => setDetailId(undefined)} />
    </>
  );
};
