import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Container, ContainerSizes } from 'components/Container';
import { Hero } from 'components/Hero';
import { Section } from 'components/Section';
import { useQueryParam } from 'hooks/util/useQueryParam';
import { Form } from './Form';
import styles from './Donate.module.css';

export const Donate = () => {
  const status = useQueryParam('status');
  const isSuccess = useMemo(() => status === 'success', [status]);

  return (
    <>
      <Helmet>
        <title>Donate</title>
      </Helmet>
      <Hero>
        {isSuccess ? (
          <>
            <h1>Thank You For Your Generosity!</h1>
            <p>
              Your kindness will go a long way! You should receive a
              confirmation email from us shortly to the email address you
              entered.
            </p>
          </>
        ) : (
          <>
            <h1>Give a Donation</h1>
            <p>You can donate in two ways. Money and time.</p>
          </>
        )}
      </Hero>
      {!isSuccess && (
        <Section>
          <Container size={ContainerSizes.Small}>
            <header className={styles.header}>
              <p>
                Enter an amount and select a cause. Your donation will be used
                to support a nominee that matches your desired cause.
              </p>
              <p>
                You get to choose your level of involvement. Only give or also
                choose to volunteer your time. Either way you will always get to
                see exactly how your donation is spent, with receipts, and you
                will get to see your contributions at work.
              </p>
            </header>
            <Form />
          </Container>
        </Section>
      )}
    </>
  );
};
