import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Form } from './Form';
import { List } from './List';
import styles from './Users.module.css';

export const AdminUsers = () => {
  return (
    <Section>
      <Container>
        <h1 className={styles.heading}>Manage Users</h1>
        <Form />
        <List />
      </Container>
    </Section>
  );
};
