import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import { Table } from 'components/Table';
import { useDeleteUser } from 'hooks/mutation/useDeleteUser';
import { useUsers } from 'hooks/query/useUsers';
import { useAuth } from 'hooks/query/useAuth';
import { User } from 'models/api/user';
import { DetailModal } from './DetailModal';
import styles from './List.module.css';

export const List = () => {
  const [detailId, setDetailId] = useState<string>();
  const { data: currentUser } = useAuth();
  const { data: users, isLoading } = useUsers();
  const { mutateAsync: deleteUser } = useDeleteUser();

  const handleDelete = async (id: string) => {
    await deleteUser(id);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (user: User) => `${user.firstName} ${user.lastName}`,
        id: 'name',
      },
      {
        Header: 'Email Address',
        accessor: 'email',
      },
      {
        Header: '',
        accessor: (user: User) => (
          <div className={styles.actions}>
            {user.id !== currentUser?.id && (
              <button
                className={styles.button}
                onClick={() => handleDelete(user.id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            )}
            <button
              className={styles.button}
              onClick={() => setDetailId(user.id)}
              type="button"
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
          </div>
        ),
        id: 'actions',
        disableSortBy: true,
      },
    ],
    [],
  );

  return (
    <>
      <Table
        columns={columns}
        data={users}
        isLoading={isLoading}
        loadingMessage="Loading users."
        noDataMessage="There are no users."
      />
      <DetailModal id={detailId} onClose={() => setDetailId(undefined)} />
    </>
  );
};
