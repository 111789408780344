import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useFetch } from 'hooks/util/useFetch';
import { Dashboard } from 'models/api/dashboard';
import { ApiError } from 'models/api/error';

export const useDashboard = () => {
  const [$fetch] = useFetch<Dashboard>();
  return useQuery<Dashboard, ApiError>(
    ['dashboard'],
    () => $fetch('/api/dashboard'),
    {
      onError: (error) => {
        toast.error(error.data.message || 'An unexpected error occurred.');
      },
    },
  );
};
