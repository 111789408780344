import { useFormik } from 'formik';
import { ChangeEvent } from 'react';
import { Button } from 'components/Button';
import { Modal, ModalProps } from 'components/Modal';
import { TextField } from 'components/TextField';
import { useCreateAllocation } from 'hooks/mutation/useCreateAllocation';
import { useDonation } from 'hooks/query/useDonation';
import { Nomination } from 'models/api/nomination';
import { getAvailableAmount } from 'utils/donation';
import { formatAmount } from 'utils/format';
import styles from './AllocationModal.module.css';
import { getAllocatedAmount } from 'utils/nomination';

interface AllocationModalProps extends ModalProps {
  id?: string;
  nomination?: Nomination;
}

export const AllocationModal = (props: AllocationModalProps) => {
  const { id, nomination, ...modalProps } = props;
  const { data: donation } = useDonation(id);
  const { mutateAsync: createAllocation } = useCreateAllocation();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: getAvailableAmount(donation) / 100,
    },
    onSubmit: async (values) => {
      if (!donation || !nomination) return false;

      await createAllocation({
        ...values,
        donationId: donation.id,
        nominationId: nomination.id,
      });

      modalProps.onClose?.();
    },
  });

  return (
    <Modal
      isOpen={!!donation && !!nomination}
      title="Allocate Funds"
      {...modalProps}
    >
      <p>
        <strong>Available Amount:</strong>{' '}
        {formatAmount(getAvailableAmount(donation))}
      </p>
      <p>
        <strong>Allocated Amount:</strong>{' '}
        {formatAmount(getAllocatedAmount(nomination))}
      </p>
      <form className={styles.form} noValidate onSubmit={formik.handleSubmit}>
        <TextField
          error={formik.errors.amount}
          label="Amount"
          name="amount"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            formik.setFieldValue('amount', Number(e.target.value))
          }
          required
          type="number"
          value={formik.values.amount}
        />
        <Button type="submit">Allocate Funds</Button>
      </form>
    </Modal>
  );
};
