import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { Nomination } from 'models/api/nomination';
import { useFetch } from 'hooks/util/useFetch';
import { ApiError } from 'models/api/error';

export const useNominations = () => {
  const [$fetch] = useFetch<Nomination[]>();
  return useQuery<Nomination[], ApiError>(
    ['nominations'],
    () => $fetch('/api/nominations'),
    {
      onError: (error) => {
        toast.error(error.data.message || 'An unexpected error occurred.');
      },
    },
  );
};
