import { faEdit, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import { Table } from 'components/Table';
import { useNominations } from 'hooks/query/useNominations';
import { Donation } from 'models/api/donation';
import { Nomination } from 'models/api/nomination';
import { formatAmount, formatDate } from 'utils/format';
import { getAllocatedAmount, getStatus } from 'utils/nomination';
import { AllocationModal } from '../AllocationModal';
import { DetailModal } from '../DetailModal';
import styles from './List.module.css';

interface ListProps {
  donation?: Donation;
  filteredCause?: string;
  filteredId?: string;
  filteredNominee?: string;
  showAccepted?: boolean;
  showCompleted?: boolean;
  showPending?: boolean;
  showRejected?: boolean;
}

export const List = (props: ListProps) => {
  const {
    donation,
    filteredCause,
    filteredId,
    filteredNominee,
    showAccepted,
    showCompleted,
    showPending,
    showRejected,
  } = props;
  const [allocationId, setAllocationId] = useState<string>();
  const [selectedId, setSelectedId] = useState<string>();
  const { data: nominations, isLoading } = useNominations();
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: (nomination: Nomination) => formatDate(nomination.createdAt),
        id: 'date',
      },
      {
        Header: 'Nominee',
        accessor: 'nominee',
      },
      {
        Header: 'Cause',
        accessor: (nomination: Nomination) => nomination.cause?.name,
        id: 'cause',
      },
      {
        Header: 'Allocated Amount',
        accessor: (nomination: Nomination) =>
          formatAmount(getAllocatedAmount(nomination)),
        id: 'allocatedAmount',
      },
      {
        Header: 'Status',
        accessor: (nomination: Nomination) => getStatus(nomination),
      },
      {
        Header: '',
        accessor: (nomination: Nomination) => (
          <div className={styles.actions}>
            {donation && (
              <button
                className={styles.button}
                onClick={() => setAllocationId(nomination.id)}
                type="button"
              >
                <FontAwesomeIcon icon={faFileInvoiceDollar} />
              </button>
            )}
            <button
              className={styles.button}
              onClick={() => setSelectedId(nomination.id)}
              type="button"
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
          </div>
        ),
        id: 'actions',
        disableSortBy: true,
      },
    ],
    [donation],
  );

  const filteredNominations = useMemo(() => {
    let filtered = nominations || [];

    if (filteredCause)
      filtered = filtered.filter(
        (nomination) => nomination.cause?.id === filteredCause,
      );

    if (filteredId)
      filtered = filtered.filter((nomination) => nomination.id === filteredId);

    if (filteredNominee) {
      filtered = filtered.filter((nomination) =>
        nomination.nominee
          .toLowerCase()
          .includes(filteredNominee.toLowerCase()),
      );
    }

    if (!showRejected)
      filtered = filtered.filter((nomination) => !nomination.isRejected);

    if (!showAccepted) {
      filtered = filtered.filter(
        (nomination) => nomination.isCompleted || !nomination.isAccepted,
      );
      console.log('filtering accepted', filtered);
    }

    if (!showCompleted) {
      filtered = filtered.filter((nomination) => !nomination.isCompleted);
      console.log('filtering completed', filtered);
    }

    if (!showPending)
      filtered = filtered.filter(
        (nomination) =>
          nomination.isRejected ||
          nomination.isAccepted ||
          nomination.isCompleted,
      );

    return filtered;
  }, [
    filteredCause,
    filteredId,
    filteredNominee,
    nominations,
    showAccepted,
    showCompleted,
    showPending,
    showRejected,
  ]);

  return (
    <>
      <Table
        columns={columns}
        data={filteredNominations}
        isLoading={isLoading}
        loadingMessage="Loading nominations."
        noDataMessage="There are no nominations."
      />
      <AllocationModal
        id={allocationId}
        donation={donation}
        onClose={() => setAllocationId(undefined)}
      />
      <DetailModal id={selectedId} onClose={() => setSelectedId(undefined)} />
    </>
  );
};
