import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { Button } from 'components/Button';
import { Container, ContainerSizes } from 'components/Container';
import { Section } from 'components/Section';
import { TextField } from 'components/TextField';
import { useAuthLogin } from 'hooks/mutation/useAuthLogin';
import styles from './Login.module.css';
import { Helmet } from 'react-helmet';

export const Login = () => {
  const { mutateAsync: login } = useAuthLogin();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      await login(values);
    },
  });

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Section>
        <Container size={ContainerSizes.Small}>
          <h1 className={styles.heading}>
            SEEIT<strong>MOVE</strong> Login
          </h1>
          <form
            className={styles.form}
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <TextField
              label="Email Address"
              name="email"
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
            />
            <TextField
              label="Password"
              name="password"
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
            />
            <Link to="/auth/forgot-password">Forgot Password</Link>
            <Button type="submit">Login</Button>
          </form>
        </Container>
      </Section>
    </>
  );
};
