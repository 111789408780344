import { SocialIcon } from 'react-social-icons';
import styles from './Socials.module.css';

export const Socials = () => {
  return (
    <div className={styles.socials}>
      <SocialIcon
        fgColor="#FFF"
        network="facebook"
        rel="noreferrer"
        style={{ height: 32, width: 32 }}
        target="_blank"
        url="http://www.facebook.com"
      />
      <SocialIcon
        fgColor="#FFF"
        network="instagram"
        rel="noreferrer"
        style={{ height: 32, width: 32 }}
        target="_blank"
        url="http://www.instagram.com"
      />
      <SocialIcon
        fgColor="#FFF"
        network="twitter"
        rel="noreferrer"
        style={{ height: 32, width: 32 }}
        target="_blank"
        url="http://www.twitter.com"
      />
      <SocialIcon
        fgColor="#FFF"
        network="youtube"
        rel="noreferrer"
        style={{ height: 32, width: 32 }}
        target="_blank"
        url="http://www.youtube.com"
      />
    </div>
  );
};
