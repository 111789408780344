import { useMediaQuery } from 'react-responsive';

export const useBreakpoints = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' });
  const isTablet = useMediaQuery({
    query: '(min-width: 768px) and (max-width: 991px)',
  });
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  return { isDesktop, isTablet, isMobile };
};
