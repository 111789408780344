import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { useDashboard } from 'hooks/query/useDashboard';

export const AdminDashboard = () => {
  const { data } = useDashboard();

  return (
    <Section>
      <Container>
        <h1>Admin Dashboard</h1>
        <p># of Donations (Last 7 Days): {data?.donationsLast7Days}</p>
        <p># of Nominations (Last 7 Days): {data?.nominationsLast7Days}</p>
      </Container>
    </Section>
  );
};
