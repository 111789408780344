import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { Button, ButtonVariants } from 'components/Button';
import { ButtonLink } from 'components/ButtonLink';
import { Modal, ModalProps } from 'components/Modal';
import { Table } from 'components/Table';
import { useUpdateNomination } from 'hooks/mutation/useUpdateNomination';
import { useNomination } from 'hooks/query/useNomination';
import { Allocation } from 'models/api/allocation';
import { Donation } from 'models/api/donation';
import { formatAmount, formatDate } from 'utils/format';
import { getAllocatedAmount, getStatus } from 'utils/nomination';
import styles from './DetailModal.module.css';

interface DetailModalProps extends ModalProps {
  id?: string;
}

export const DetailModal = (props: DetailModalProps) => {
  const { id, ...modalProps } = props;
  const { data: nomination } = useNomination(id);
  const { mutateAsync: updateNomination } = useUpdateNomination(id);

  const donorColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (allocation: Allocation & { donation: Donation }) =>
          `${allocation.donation?.firstName} ${allocation.donation?.lastName}`,
        id: 'name',
      },
      {
        Header: 'Email Address',
        accessor: (allocation: Allocation & { donation: Donation }) =>
          allocation.donation?.email,
        id: 'email',
      },
      {
        Header: 'Amount',
        accessor: (allocation: Allocation & { donation: Donation }) =>
          formatAmount(allocation.amount),
        id: 'amount',
      },
      {
        Header: 'Volunteer',
        accessor: (allocation: Allocation & { donation: Donation }) =>
          allocation.donation?.isAvailableToVolunteerTime && (
            <FontAwesomeIcon icon={faCheck} />
          ),
        options: {
          align: 'center',
        },
      },
    ],
    [],
  );

  const handleAccept = async () => {
    await updateNomination({ isAccepted: true, isRejected: false });
  };

  const handleReject = async () => {
    await updateNomination({ isAccepted: false, isRejected: true });
  };

  const handleComplete = async () => {
    await updateNomination({ isCompleted: true });
  };

  const actions = useMemo(
    () =>
      [
        !nomination?.isAccepted && !nomination?.isCompleted && (
          <Button
            key="accept"
            onClick={handleAccept}
            variant={ButtonVariants.Secondary}
          >
            Accept
          </Button>
        ),
        !nomination?.isRejected && !nomination?.isCompleted && (
          <Button
            key="reject"
            onClick={handleReject}
            variant={ButtonVariants.Secondary}
          >
            Reject
          </Button>
        ),
        nomination?.isAccepted && !nomination?.isCompleted && (
          <ButtonLink
            key="allocate"
            to={`/admin/donations?nominationId=${nomination.id}`}
          >
            Allocate Funds
          </ButtonLink>
        ),
        nomination?.isAccepted && !nomination?.isCompleted && (
          <Button
            key="complete"
            onClick={handleComplete}
            variant={ButtonVariants.Accent}
          >
            Complete
          </Button>
        ),
      ].filter(Boolean),
    [nomination],
  );

  return (
    <Modal
      actions={actions}
      isOpen={!!nomination}
      title="Manage Nomination"
      {...modalProps}
    >
      {nomination && (
        <>
          <section className={styles.section}>
            <h2>Nomination Details</h2>
            <small className={styles.id}>
              <strong>ID:</strong> {nomination.id}
            </small>
            <dl className={styles.details}>
              <div>
                <dt>Date</dt>
                <dd>{formatDate(nomination.createdAt)}</dd>
              </div>
              <div>
                <dt>Status</dt>
                <dd>{getStatus(nomination)}</dd>
              </div>
              <div>
                <dt>Nominee</dt>
                <dd>{nomination.nominee}</dd>
              </div>
              <div>
                <dt>Allocated Funds</dt>
                <dd>{formatAmount(getAllocatedAmount(nomination))}</dd>
              </div>
            </dl>
          </section>
          <section className={styles.section}>
            <h2>Description</h2>
            <p className={styles.description}>{nomination.description}</p>
          </section>
          <section className={styles.section}>
            <h2>Contact Details</h2>
            <dl className={styles.details}>
              <div>
                <dt>Name</dt>
                <dd>
                  {nomination.firstName} {nomination.lastName}
                </dd>
              </div>
              <div>
                <dt>Email Address</dt>
                <dd>{nomination.email}</dd>
              </div>
              <div>
                <dt>Phone Number</dt>
                <dd>{nomination.phone}</dd>
              </div>
              <div>
                <dt>Zip Code</dt>
                <dd>{nomination.zip}</dd>
              </div>
            </dl>
          </section>
          <section className={styles.section}>
            <h2>Donors</h2>
            <Table
              columns={donorColumns}
              data={nomination.allocations}
              noDataMessage="There are no donors."
            />
          </section>
        </>
      )}
    </Modal>
  );
};
