import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Container } from 'components/Container';
import { Logo } from 'components/Logo';
// import { ThemeToggle } from 'components/ThemeToggle';
import { useAuthLogout } from 'hooks/mutation/useAuthLogout';
import { useAuth } from 'hooks/query/useAuth';
import { useBreakpoints } from 'hooks/util/useBreakpoints';
import styles from './Header.module.css';

interface HeaderProps {
  onMobileNavClick: () => void;
}

export const Header = (props: HeaderProps) => {
  const { onMobileNavClick } = props;
  const { isMobile } = useBreakpoints();
  const { data: user } = useAuth();
  const { mutateAsync: logout } = useAuthLogout();

  return (
    <header className={styles.header}>
      <Container className={styles.container}>
        {isMobile && (
          <button
            className={styles['mobile-nav-trigger']}
            onClick={onMobileNavClick}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        )}
        <Link className={styles.logo} to="/">
          <Logo />
        </Link>
        {!isMobile && (
          <nav className={styles.nav}>
            <ul>
              {user && (
                <>
                  <li>{user.email}</li>
                  <li>
                    <button className={styles.logout} onClick={() => logout()}>
                      Logout
                    </button>
                  </li>
                </>
              )}
              {/* <li>
                <ThemeToggle />
              </li> */}
            </ul>
          </nav>
        )}
      </Container>
    </header>
  );
};
