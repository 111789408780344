import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useFetch } from 'hooks/util/useFetch';
import { CreateDonationRequest, DonationSession } from 'models/api/donation';
import { ApiError } from 'models/api/error';

export const useCreateDonation = () => {
  const [$fetch] = useFetch<DonationSession>();
  return useMutation<DonationSession, ApiError, CreateDonationRequest>(
    (donation: CreateDonationRequest) =>
      $fetch('/api/donations', {
        body: donation,
        method: 'POST',
      }),
    {
      onError: (error) => {
        toast.error(error.data.message || 'An unexpected error occurred.');
      },
    },
  );
};
