import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { Donation } from 'models/api/donation';
import { useFetch } from 'hooks/util/useFetch';
import { ApiError } from 'models/api/error';

export const useDonation = (id?: string) => {
  const [$fetch] = useFetch<Donation>();
  return useQuery<Donation, ApiError>(
    ['donations', id],
    () => $fetch(`/api/donations/${id as string}`),
    {
      enabled: !!id,
      onError: (error) => {
        toast.error(error.data.message || 'An unexpected error occurred.');
      },
    },
  );
};
