import { useFormik } from 'formik';
import { Button, ButtonVariants } from 'components/Button';
import { TextField } from 'components/TextField';
import { useCreateUser } from 'hooks/mutation/useCreateUser';
import styles from './Form.module.css';
import { validationSchema } from './validationSchema';
import { useState } from 'react';

export const Form = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { mutateAsync } = useCreateUser();
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    onSubmit: async (values, { resetForm }) => {
      await mutateAsync(values);
      resetForm();
    },
    validateOnChange: false,
    validationSchema,
  });

  return (
    <div className={styles.container}>
      {!isOpen ? (
        <Button
          className={styles.button}
          onClick={() => setIsOpen(true)}
          variant={ButtonVariants.Secondary}
        >
          Add User
        </Button>
      ) : (
        <form className={styles.form} noValidate onSubmit={formik.handleSubmit}>
          <TextField
            error={formik.errors.firstName}
            label="First Name"
            name="firstName"
            onChange={formik.handleChange}
            required
            value={formik.values.firstName}
          />
          <TextField
            error={formik.errors.lastName}
            label="Last Name"
            name="lastName"
            onChange={formik.handleChange}
            required
            value={formik.values.lastName}
          />
          <TextField
            error={formik.errors.email}
            label="Email Address"
            name="email"
            onChange={formik.handleChange}
            required
            type="email"
            value={formik.values.email}
          />
          <Button type="submit">Add User</Button>
          <Button
            onClick={() => setIsOpen(false)}
            variant={ButtonVariants.Secondary}
          >
            Cancel
          </Button>
        </form>
      )}
    </div>
  );
};
