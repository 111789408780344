import { useFormik } from 'formik';
import { Button } from 'components/Button';
import { TextField } from 'components/TextField';
import { useUpdateUser } from 'hooks/mutation/useUpdateUser';
import styles from './EditForm.module.css';
import { User } from 'models/api/user';
import { validationSchema } from './validationSchema';

interface EditFormProps {
  onSuccess?: () => void;
  user: User;
}

export const EditForm = (props: EditFormProps) => {
  const { onSuccess, user } = props;
  const { mutateAsync: updateUser } = useUpdateUser(user.id);
  const formik = useFormik({
    initialValues: {
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      email: user.email || '',
    },
    onSubmit: async (values) => {
      await updateUser(values);
      onSuccess?.();
    },
    validationSchema,
  });

  return (
    <form className={styles.form} noValidate onSubmit={formik.handleSubmit}>
      <div className={styles.name}>
        <TextField
          error={formik.errors.firstName}
          label="First Name"
          name="firstName"
          onChange={formik.handleChange}
          required
          value={formik.values.firstName}
        />
        <TextField
          error={formik.errors.lastName}
          label="Last Name"
          name="lastName"
          onChange={formik.handleChange}
          required
          value={formik.values.lastName}
        />
      </div>
      <TextField
        error={formik.errors.email}
        label="Email Address"
        name="email"
        onChange={formik.handleChange}
        required
        type="email"
        value={formik.values.email}
      />
      <Button type="submit">Save Changes</Button>
    </form>
  );
};
