import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { Table } from 'components/Table';
import { useCauses } from 'hooks/query/useCauses';
import { useDeleteCause } from 'hooks/mutation/useDeleteCause';
import { Cause } from 'models/api/cause';
import styles from './List.module.css';

export const List = () => {
  const { data: causes, isLoading } = useCauses();
  const { mutateAsync: deleteCause } = useDeleteCause();

  const handleDelete = async (id: string) => {
    await deleteCause(id);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Donations',
        accessor: (cause: Cause) => cause._count?.donations,
        id: 'donations',
      },
      {
        Header: 'Nominations',
        accessor: (cause: Cause) => cause._count?.nominations,
        id: 'nominations',
      },
      {
        Header: '',
        accessor: (cause: Cause) =>
          cause._count?.donations < 1 &&
          cause._count?.nominations < 1 && (
            <button
              className={styles.button}
              onClick={() => handleDelete(cause.id)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          ),
        id: 'actions',
        disableSortBy: true,
      },
    ],
    [causes],
  );

  return (
    <Table
      columns={columns}
      data={causes}
      isLoading={isLoading}
      loadingMessage="Loading causes."
      noDataMessage="There are no causes."
    />
  );
};
