import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useFetch } from 'hooks/util/useFetch';
import { Cause } from 'models/api/cause';
import { ApiError } from 'models/api/error';

export const useCauses = () => {
  const [$fetch] = useFetch<Cause[]>();
  return useQuery<Cause[], ApiError>(['causes'], () => $fetch('/api/causes'), {
    onError: (error) => {
      toast.error(error.data.message || 'An unexpected error occurred.');
    },
  });
};
