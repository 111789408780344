import { faAngleRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Socials } from 'components/Socials';
import styles from './MobileNav.module.css';
import { useAuth } from 'hooks/query/useAuth';
import { Button, ButtonVariants } from 'components/Button';
import { ThemeToggle } from 'components/ThemeToggle';
import { useAuthLogout } from 'hooks/mutation/useAuthLogout';

interface MobileNavProps {
  isOpen?: boolean;
  onClose: () => void;
}

export const MobileNav = (props: MobileNavProps) => {
  const { isOpen, onClose } = props;
  const { data: user } = useAuth();
  const { mutateAsync: logout } = useAuthLogout();

  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <>
          <motion.div
            className={styles.overlay}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
          />
          <motion.div
            className={styles.drawer}
            initial={{ transform: 'translateX(-100%)' }}
            animate={{ transform: 'translateX(0%)' }}
            exit={{ transform: 'translateX(-100%)' }}
            transition={{ bounce: 0 }}
          >
            <header className={styles.header}>
              <button className={styles.close} onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </header>
            <nav className={styles.nav}>
              <ul>
                <li>
                  <Link to="/">
                    <span>Home</span>
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Link>
                </li>
                <li>
                  <Link to="/donate">
                    <span>Donate</span>
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Link>
                </li>
                <li>
                  <Link to="/nominate">
                    <span>Nominate</span>
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us">
                    <span>Contact Us</span>
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Link>
                </li>
              </ul>
            </nav>
            <footer className={styles.footer}>
              {user && (
                <div className={styles.admin}>
                  <div className={styles.details}>
                    <p>Welcome, {user.firstName}!</p>
                    <Link className={styles.link} to="/admin">
                      Admin Dashboard <FontAwesomeIcon icon={faAngleRight} />
                    </Link>
                  </div>
                  <Button
                    className={styles.button}
                    onClick={() => logout()}
                    variant={ButtonVariants.SecondaryInverse}
                  >
                    Logout
                  </Button>
                </div>
              )}
              <div className={styles.socials}>
                <strong>Follow Us</strong>
                <Socials />
              </div>
              <ThemeToggle />
            </footer>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};
