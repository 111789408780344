import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'hooks/query/useAuth';

export const Protect = (props: PropsWithChildren<unknown>) => {
  const { children } = props;
  const { data: auth } = useAuth();

  if (auth) return <>{children}</>;

  return <Navigate to={`/auth/login?redirectTo=${window.location.pathname}`} />;
};
