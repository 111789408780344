import { Button, ButtonVariants } from 'components/Button';
import { Modal, ModalProps } from 'components/Modal';
import { useUser } from 'hooks/query/useUser';
import { useState } from 'react';
import styles from './DetailModal.module.css';
import { EditForm } from './EditForm';

interface DetailModalProps extends ModalProps {
  id?: string;
}

export const DetailModal = (props: DetailModalProps) => {
  const { id, ...modalProps } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { data: user } = useUser(id);

  const actions = [
    isEditing ? (
      <Button key="cancel" onClick={() => setIsEditing(false)}>
        Cancel
      </Button>
    ) : (
      <>
        <Button
          key="edit"
          onClick={() => setIsEditing(true)}
          variant={ButtonVariants.Secondary}
        >
          Edit
        </Button>
      </>
    ),
  ];

  return (
    <Modal
      actions={actions}
      isOpen={!!user}
      title="Manage User"
      {...modalProps}
    >
      {user && (
        <>
          {isEditing ? (
            <EditForm onSuccess={() => setIsEditing(false)} user={user} />
          ) : (
            <dl className={styles.details}>
              <dt>Name</dt>
              <dd>
                {user.firstName} {user.lastName}
              </dd>
              <dt>Email Address</dt>
              <dd>{user.email}</dd>
            </dl>
          )}
        </>
      )}
    </Modal>
  );
};
