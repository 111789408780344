/* eslint-disable */
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required('Required')
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, 'Must be at least 8 characters and include 1 lowercase letter, 1 uppercase letter, and 1 number'),
  passwordConfirmation: yup
    .string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    }),
});
