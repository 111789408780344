import { Nomination } from 'models/api/nomination';

export const getStatus = (nomination: Nomination) => {
  if (nomination.isCompleted) {
    return 'Completed';
  } else if (nomination.isAccepted) {
    return 'Accepted';
  } else if (nomination.isRejected) {
    return 'Rejected';
  } else {
    return 'Pending';
  }
};

export const getAllocatedAmount = (nomination?: Nomination): number => {
  return (
    nomination?.allocations?.reduce(
      (total, allocation) => (total += allocation.amount),
      0,
    ) || 0
  );
};
