import { faDonate, faVoteYea } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styles from './Blocks.module.css';

export const Blocks = () => {
  return (
    <section className={styles.blocks}>
      <Link className={styles.block} to="/donate">
        <FontAwesomeIcon className={styles.icon} icon={faDonate} />
        <strong>Give a Donation</strong>
      </Link>
      <Link className={styles.block} to="nominate">
        <FontAwesomeIcon className={styles.icon} icon={faVoteYea} />
        <strong>Submit a Nomination</strong>
      </Link>
    </section>
  );
};
