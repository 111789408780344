import { useEffect, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import { Helmet } from 'react-helmet';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { Protect } from 'components/Protect';
import { Spinner } from 'components/Spinner';
import { useAuth } from 'hooks/query/useAuth';
// import { useCsrf } from 'hooks/query/useCsrf';
import { useBreakpoints } from 'hooks/util/useBreakpoints';
import { Admin } from 'views/Admin';
import { AdminCauses } from 'views/Admin/Causes';
import { AdminDashboard } from 'views/Admin/Dashboard';
import { AdminDonations } from 'views/Admin/Donations';
import { AdminNominations } from 'views/Admin/Nominations';
import { AdminUsers } from 'views/Admin/Users';
import { Auth } from 'views/Auth';
import { ForgotPassword } from 'views/Auth/ForgotPassword';
import { Login } from 'views/Auth/Login';
import { ResetPassword } from 'views/Auth/ResetPassword';
import { ContactUs } from 'views/ContactUs';
import { Donate } from 'views/Donate';
import { Error } from 'views/Error';
import { Home } from 'views/Home';
import { PrivacyPolicy } from 'views/Legal/PrivacyPolicy';
import { TermsOfService } from 'views/Legal/TermsOfService';
import { Nominate } from 'views/Nominate';
import { Footer } from './Footer';
import { Header } from './Header';
import { Nav } from './Nav';
import styles from './App.module.css';
import { Button, ButtonVariants } from 'components/Button';
import { MobileNav } from './MobileNav';

function App() {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false);
  const location = useLocation();
  const { isLoading, isError } = useAuth();
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    setIsMobileNavOpen(false);
    document.documentElement.style.scrollBehavior = 'auto';
    setTimeout(() => window.scrollTo(0, 0), 5);
    setTimeout(
      () => (document.documentElement.style.scrollBehavior = 'smooth'),
      5,
    );
  }, [location]);

  if (isLoading) return <Spinner className={styles.spinner} />;
  if (isError) return <Error />;

  return (
    <>
      <Helmet titleTemplate="%s | SeeItMove" />
      <div className={styles.app}>
        <Header onMobileNavClick={() => setIsMobileNavOpen(true)} />
        {isMobile ? (
          <MobileNav
            isOpen={isMobileNavOpen}
            onClose={() => setIsMobileNavOpen(false)}
          />
        ) : (
          <Nav />
        )}
        <main className={styles.main}>
          <Routes>
            <Route element={<Home />} index />
            <Route element={<Nominate />} path="nominate" />
            <Route element={<Donate />} path="donate" />
            <Route
              element={
                <Protect>
                  <Admin />
                </Protect>
              }
              path="admin"
            >
              <Route element={<AdminDashboard />} index />
              <Route element={<AdminCauses />} path="causes" />
              <Route element={<AdminDonations />} path="donations" />
              <Route element={<AdminNominations />} path="nominations" />
              <Route element={<AdminUsers />} path="users" />
            </Route>
            <Route element={<Auth />} path="auth">
              <Route element={<ForgotPassword />} path="forgot-password" />
              <Route element={<Login />} path="login" />
              <Route element={<ResetPassword />} path="reset-password" />
            </Route>
            <Route path="legal">
              <Route element={<PrivacyPolicy />} path="privacy-policy" />
              <Route element={<TermsOfService />} path="terms-of-service" />
            </Route>
            <Route element={<ContactUs />} path="contact-us" />
          </Routes>
        </main>
        <Footer />
      </div>
      <CookieConsent
        ButtonComponent={(props) => (
          <Button {...props} variant={ButtonVariants.Accent} />
        )}
        buttonStyle={{ margin: '1rem' }}
        disableButtonStyles
        style={{
          alignItems: 'center',
          backgroundColor: 'var(--color-primary)',
        }}
      >
        <p>
          We use cookies to ensure that we give you the best experience on our
          website. If you continue to use this site we will assume that you are
          happy with it.
        </p>
        <p>
          For more information, view our{' '}
          <Link to="/legal/privacy-policy">Privacy Policy</Link>.
        </p>
      </CookieConsent>
    </>
  );
}

export const AppWrapper = () => {
  // const { isLoading } = useCsrf();

  // if (isLoading) return <Spinner className={styles.spinner} />;

  return <App />;
};

export default AppWrapper;
