import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/query/useAuth';
import { useQueryParam } from 'hooks/util/useQueryParam';

export const Auth = () => {
  const navigate = useNavigate();
  const redirectTo = useQueryParam('redirectTo');
  const { data: user } = useAuth();

  useEffect(() => {
    if (user) navigate(redirectTo || '/');
  }, [user]);

  return <Outlet />;
};
