import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useFetch } from 'hooks/util/useFetch';
import {
  AuthResetPasswordRequest,
  AuthResetPasswordResponse,
} from 'models/api/auth';
import { ApiError } from 'models/api/error';

export const useAuthResetPassword = () => {
  const [$fetch] = useFetch<AuthResetPasswordResponse>();
  return useMutation<
    AuthResetPasswordResponse,
    ApiError,
    AuthResetPasswordRequest
  >(
    (values) =>
      $fetch('/api/auth/reset-password', {
        body: values,
        method: 'POST',
      }),
    {
      onError: (error) => {
        toast.error(error.data.message || 'An unexpected error occurred.');
      },
      onSuccess: (data) => {
        toast.success(data.message);
      },
    },
  );
};
