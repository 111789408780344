import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';

interface ThemeContextValues {
  isDarkMode: boolean;
  toggleTheme: () => void;
}

export const ThemeContext = createContext<ThemeContextValues>({
  isDarkMode: false,
  toggleTheme: () => undefined,
});

export const ThemeProvider = (props: PropsWithChildren<unknown>) => {
  const { children } = props;
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

  useEffect(() => {
    let theme = 'light';
    const cachedTheme = window.localStorage.getItem('theme');

    if (cachedTheme) {
      theme = cachedTheme;
    } else if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)')
    ) {
      theme = 'dark';
    }

    if (theme === 'dark') {
      setIsDarkMode(true);
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark');
      window.localStorage.setItem('theme', 'dark');
    } else {
      document.body.classList.remove('dark');
      window.localStorage.setItem('theme', 'light');
    }
  }, [isDarkMode]);

  const toggleTheme = useCallback(
    () => setIsDarkMode((prevValue: boolean) => !prevValue),
    [],
  );

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
