import { useFormik } from 'formik';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/Button';
import { Container, ContainerSizes } from 'components/Container';
import { Hero } from 'components/Hero';
import { Section } from 'components/Section';
import { TextField } from 'components/TextField';
import { useAuthResetPassword } from 'hooks/mutation/useAuthResetPassword';
import { useQueryParam } from 'hooks/util/useQueryParam';
import { validationSchema } from './validationSchema';
import styles from './ResetPassword.module.css';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const token = useQueryParam('token');
  const { mutateAsync: resetPassword } = useAuthResetPassword();
  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    onSubmit: async (values, { resetForm }) => {
      if (token) {
        await resetPassword({ password: values.password, token });
        resetForm();
        navigate('/auth/login');
      }
    },
    validationSchema,
  });

  useEffect(() => {
    if (!token) navigate('/auth/forgot-password');
  }, [token]);

  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <Hero>
        <h1>Reset Your Password</h1>
        <p>Enter your new password below.</p>
      </Hero>
      <Section>
        <Container size={ContainerSizes.Small}>
          <form
            className={styles.form}
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <TextField
              error={formik.errors.password}
              label="Password"
              name="password"
              onChange={formik.handleChange}
              required
              type="password"
              value={formik.values.password}
            />
            <TextField
              error={formik.errors.passwordConfirmation}
              label="Confirm Password"
              name="passwordConfirmation"
              onChange={formik.handleChange}
              required
              type="password"
              value={formik.values.passwordConfirmation}
            />
            <Button type="submit">Reset Password</Button>
          </form>
        </Container>
      </Section>
    </>
  );
};
