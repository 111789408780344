import { useMemo } from 'react';
import { ButtonLink } from 'components/ButtonLink';
import { Modal, ModalProps } from 'components/Modal';
import { Table } from 'components/Table';
import { useDonation } from 'hooks/query/useDonation';
import { Allocation } from 'models/api/allocation';
import { Nomination } from 'models/api/nomination';
import { getAvailableAmount } from 'utils/donation';
import { formatAmount, formatDate } from 'utils/format';
import styles from './DetailModal.module.css';

interface DetailModalProps extends ModalProps {
  id?: string;
}

export const DetailModal = (props: DetailModalProps) => {
  const { id, ...modalProps } = props;
  const { data: donation } = useDonation(id);

  const columns = useMemo(
    () => [
      {
        Header: 'Nominee',
        accessor: (allocation: Allocation & { nomination: Nomination }) =>
          allocation.nomination?.nominee,
        id: 'nominee',
      },
      {
        Header: 'Amount',
        accessor: (allocation: Allocation & { nomination: Nomination }) =>
          formatAmount(allocation.amount),
        id: 'amount',
      },
    ],
    [],
  );

  const actions = useMemo(
    () =>
      [
        donation && (
          <ButtonLink
            key="allocate"
            to={`/admin/nominations?donationId=${donation.id}`}
          >
            Allocate Funds
          </ButtonLink>
        ),
      ].filter(Boolean),
    [donation],
  );

  return (
    <Modal
      actions={actions}
      isOpen={!!donation}
      title="Manage Donation"
      {...modalProps}
    >
      {donation && (
        <>
          <section className={styles.section}>
            <h2>Donation Details</h2>
            <div className={styles.id}>
              <small>
                <strong>ID:</strong> {donation.id}
              </small>
              <small>
                <strong>Stripe ID:</strong> {donation.paymentIntentId}
              </small>
            </div>
            <dl className={styles.details}>
              <div>
                <dt>Date</dt>
                <dd>{formatDate(donation.createdAt)}</dd>
              </div>
              <div>
                <dt>Amount</dt>
                <dd>{formatAmount(donation.amount)}</dd>
              </div>
              <div>
                <dt>Cause</dt>
                <dd>{donation.cause?.name}</dd>
              </div>
              <div>
                <dt>Available Amount</dt>
                <dd>{formatAmount(getAvailableAmount(donation))}</dd>
              </div>
              <div>
                <dt>Is Available to Volunteer</dt>
                <dd>{donation.isAvailableToVolunteerTime ? 'Yes' : 'No'}</dd>
              </div>
            </dl>
          </section>
          <section className={styles.section}>
            <h2>Description</h2>
            <p className={styles.description}>{donation.description}</p>
          </section>
          <section className={styles.section}>
            <h2>Contact Details</h2>
            <dl className={styles.details}>
              <div>
                <dt>Name</dt>
                <dd>
                  {donation.firstName} {donation.lastName}
                </dd>
              </div>
              <div>
                <dt>Email Address</dt>
                <dd>{donation.email}</dd>
              </div>
              <div>
                <dt>Phone Number</dt>
                <dd>{donation.phone}</dd>
              </div>
              <div>
                <dt>Zip Code</dt>
                <dd>{donation.zip}</dd>
              </div>
            </dl>
          </section>
          <section className={styles.section}>
            <h2>Allocations</h2>
            <Table
              columns={columns}
              data={donation.allocations}
              noDataMessage="There are no allocations."
            />
          </section>
        </>
      )}
    </Modal>
  );
};
