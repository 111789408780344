import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import styles from './Section.module.css';

interface SectionProps {
  className?: string;
}

export const Section = (props: PropsWithChildren<SectionProps>) => {
  const { children, className } = props;

  return (
    <section className={classNames(styles.section, className)}>
      {children}
    </section>
  );
};
