import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { User } from 'models/api/user';
import { useFetch } from 'hooks/util/useFetch';
import { ApiError } from 'models/api/error';

export const useUsers = () => {
  const [$fetch] = useFetch<User[]>();
  return useQuery<User[], ApiError>(['users'], () => $fetch('/api/users'), {
    onError: (error) => {
      toast.error(error.data.message || 'An unexpected error occurred.');
    },
  });
};
