import { ReactComponent as CreditCardImage } from 'assets/illustrations/undraw_credit_card_payments_re_qboh.svg';
import { ReactComponent as LetterImage } from 'assets/illustrations/undraw_confidential_letter_w6ux.svg';
import { ReactComponent as PowerfulImage } from 'assets/illustrations/undraw_powerful_re_frhr.svg';
import { ReactComponent as ThankYouImage } from 'assets/illustrations/undraw_super_thank_you_re_f8bo.svg';
import { ButtonVariants } from 'components/Button';
import { ButtonLink } from 'components/ButtonLink';
import styles from './Onboarding.module.css';

export const Onboarding = () => {
  return (
    <div className={styles.onboarding}>
      <h2>How It Works</h2>
      <h3>Step 1</h3>
      <div className={styles['step-1']}>
        <div className={styles.step}>
          <div>
            <LetterImage />
          </div>
          <h4>Submit a Nomination</h4>
          <p>
            Tell us about a person or organization that needs assistance. We
            will contact you to vet the nominee and verify all the information.
          </p>
          <div>
            <ButtonLink to="/nominate" variant={ButtonVariants.Secondary}>
              Get Started
            </ButtonLink>
          </div>
        </div>
        <div className={styles.step}>
          <div>
            <CreditCardImage />
          </div>
          <h4>Give a Donation</h4>
          <p>
            Contribute as much or as little as you can to the cause of your
            choosing. Every little bit can help make a difference.
          </p>
          <div>
            <ButtonLink to="/donate" variant={ButtonVariants.Secondary}>
              Get Started
            </ButtonLink>
          </div>
        </div>
      </div>
      <h3>Step 2</h3>
      <div className={styles['step-2']}>
        <div className={styles.step}>
          <div>
            <PowerfulImage />
          </div>
          <h4>Become a Supporter</h4>
          <p>
            Our team will allocate donations to support nominations based on the
            donor's chosen cause. At this point, donors will also have the
            opportunity to volunteer their time.
          </p>
        </div>
      </div>
      <h3>Step 3</h3>
      <div className={styles['step-2']}>
        <div className={styles.step}>
          <div>
            <ThankYouImage />
          </div>
          <h4>Receive Updates</h4>
          <p>
            You will receive email updates on your supported cause, including
            copies of receipts showing exactly how your money was used. In
            additiona, you will also receive photos and videos showing your
            contributions in action.
          </p>
          <p>
            You can make a difference in someone's life. This is how you SEEIT
            <strong>MOVE</strong>!
          </p>
          <div className={styles.buttons}>
            <ButtonLink to="/donate">Give a Donation</ButtonLink>
            <ButtonLink to="/nominate">Submit a Nomination</ButtonLink>
          </div>
        </div>
      </div>
    </div>
  );
};
