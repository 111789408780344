import { useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import { Button } from 'components/Button';
import { Container, ContainerSizes } from 'components/Container';
import { Section } from 'components/Section';
import { TextField } from 'components/TextField';
import { useAuthForgotPassword } from 'hooks/mutation/useAuthForgotPassword';
import styles from './ForgotPassword.module.css';
import { Hero } from 'components/Hero';

export const ForgotPassword = () => {
  const { mutateAsync: forgotPassword } = useAuthForgotPassword();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values, { resetForm }) => {
      await forgotPassword(values);
      resetForm();
    },
  });

  return (
    <>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <Hero>
        <h1>Forgot Your Password?</h1>
        <p>
          That's okay, it happens! Enter your email address below. You will
          receive a verification email with a link to reset your password.
        </p>
      </Hero>
      <Section>
        <Container size={ContainerSizes.Small}>
          <form
            className={styles.form}
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <TextField
              label="Email Address"
              name="email"
              onChange={formik.handleChange}
              required
              type="email"
              value={formik.values.email}
            />
            <Button type="submit">Send Password Reset</Button>
          </form>
        </Container>
      </Section>
    </>
  );
};
