import { useFormik } from 'formik';
import { Button } from 'components/Button';
import { TextArea } from 'components/TextArea';
import { TextField } from 'components/TextField';
import { useCreateContact } from 'hooks/mutation/useCreateContact';
import styles from './Form.module.css';

export const Form = () => {
  const { mutateAsync: createContact } = useCreateContact();
  const formik = useFormik({
    initialValues: {
      email: '',
      message: '',
    },
    onSubmit: async (values, { resetForm }) => {
      await createContact(values);
      resetForm();
    },
  });

  return (
    <form className={styles.form} noValidate onSubmit={formik.handleSubmit}>
      <TextField
        label="Email Address"
        name="email"
        onChange={formik.handleChange}
        required
        type="email"
        value={formik.values.email}
      />
      <TextArea
        label="Message"
        name="message"
        onChange={formik.handleChange}
        required
        value={formik.values.message}
      />
      <Button type="submit">Submit Message</Button>
    </form>
  );
};
