import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Form } from './Form';
import { List } from './List';
import styles from './Causes.module.css';

export const AdminCauses = () => {
  return (
    <Section>
      <Container>
        <h1 className={styles.heading}>Manage Causes</h1>
        <Form />
        <List />
      </Container>
    </Section>
  );
};
