import mainImage from 'assets/images/visual-stories-micheile-f030K9IzpcM-unsplash.jpg';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import styles from './Hero.module.css';

interface HeroProps {
  className?: string;
}

export const Hero = (props: PropsWithChildren<HeroProps>) => {
  const { children, className } = props;

  return (
    <section
      className={classNames(styles.hero, className)}
      style={{ backgroundImage: `url(${mainImage})` }}
    >
      <div className={styles.content}>{children}</div>
    </section>
  );
};
