import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useFetch } from 'hooks/util/useFetch';
import { ApiError } from 'models/api/error';
import { formatAmount } from 'utils/format';
import { Allocation, CreateAllocationRequest } from 'models/api/allocation';

export const useCreateAllocation = () => {
  const [$fetch] = useFetch<Allocation>();
  const queryClient = useQueryClient();

  return useMutation<Allocation, ApiError, CreateAllocationRequest>(
    (values) =>
      $fetch(`/api/allocations`, {
        body: values,
        method: 'POST',
      }),
    {
      onError: (error) => {
        toast.error(error.data.message || 'An unexpected error occurred.');
      },
      onSuccess: async (data) => {
        toast.success(`${formatAmount(data.amount)} successfully allocated`);
        await queryClient.invalidateQueries(['donations']);
        await queryClient.invalidateQueries(['nominations']);
      },
    },
  );
};
