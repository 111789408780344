import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useFetch } from 'hooks/util/useFetch';
import { CreateNominationRequest, Nomination } from 'models/api/nomination';
import { ApiError } from 'models/api/error';

export const useCreateNomination = () => {
  const [$fetch] = useFetch<Nomination>();
  return useMutation<Nomination, ApiError, CreateNominationRequest>(
    (nomination: CreateNominationRequest) =>
      $fetch('/api/nominations', {
        body: nomination,
        method: 'POST',
      }),
    {
      onError: (error) => {
        toast.error(error.data.message || 'An unexpected error occurred.');
      },
    },
  );
};
