import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Hero } from 'components/Hero';
import { useQueryParam } from 'hooks/util/useQueryParam';
import { Form } from './Form';

export const Nominate = () => {
  const status = useQueryParam('status');
  const isSuccess = useMemo(() => status === 'success', [status]);

  return (
    <>
      <Helmet>
        <title>Nominate</title>
      </Helmet>
      <Hero>
        {isSuccess ? (
          <>
            <h1>Thank You For Your Generosity!</h1>
            <p>
              Your kindess will go a long way! You should receive a confirmation
              email from us shortly to the email address you entered.
            </p>
          </>
        ) : (
          <>
            <h1>Submit a Nomination</h1>
            <p>
              Along with the different avenues we use to help you can nominate
              to a specific person, organization, or need that may arise. When
              you nominate you will be contacted, and all the information will
              be vetted.
            </p>
          </>
        )}
      </Hero>
      {!isSuccess && <Form />}
    </>
  );
};
