import { format } from 'date-fns';

export const formatAmount = (amount = 0): string => {
  return (amount / 100).toLocaleString('en-US', {
    currency: 'USD',
    style: 'currency',
  });
};

export const formatDate = (date = ''): string => {
  return format(new Date(date), 'MM/dd/yyyy');
};
