// import { useCsrf } from 'hooks/query/useCsrf';
import { $fetch, FetchOptions } from 'ohmyfetch';
import { useCallback } from 'react';

type CallbackFunc<T> = (
  request: string,
  opts?: FetchOptions<'json'>,
) => Promise<T>;

export const useFetch = <T = void>(): [CallbackFunc<T>] => {
  // const { data: csrf } = useCsrf();
  const callback = useCallback(
    async (request: string, opts?: FetchOptions<'json'>) => {
      return $fetch<T, 'json'>(
        `${process.env.REACT_APP_API_HOST || ''}${request}`,
        {
          credentials: 'include',
          // headers: { 'x-csrf-token': csrf as string },
          ...opts,
        },
      );
    },
    [],
  );

  return [callback];
};
