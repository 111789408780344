import { Link } from 'react-router-dom';
import { Container } from 'components/Container';
import { Socials } from 'components/Socials';
import styles from './Footer.module.css';

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container className={styles.container}>
        <Socials />
        <ul className={styles.links}>
          <li>
            <Link to="/admin">Admin</Link>
          </li>
          <li>
            <Link to="/legal/terms-of-service">Terms of Service</Link>
          </li>
          <li>
            <Link to="/legal/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/contact-us">Contact Us</Link>
          </li>
        </ul>
        <p>&copy; 2021 SeeItMove, All Rights Reserved</p>
      </Container>
    </footer>
  );
};
