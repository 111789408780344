import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  amount: yup.string().required('Required'),
  causeId: yup.string().required('Required'),
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  email: yup.string().email('Invalid email').required('Required'),
  phone: yup.string().required('Required'),
  zip: yup.string().required('Required'),
});
