import { ReactComponent as ErrorImage } from 'assets/illustrations/undraw_server_down_s-4-lk.svg';
import { Logo } from 'components/Logo';
import styles from './Error.module.css';

export const Error = () => {
  return (
    <div className={styles.error}>
      <div className={styles.content}>
        <Logo />
        <ErrorImage className={styles.image} />
        <h1>Well... This is Not Like Us :(</h1>
        <p>We are working to fix the problem. Please check back later!</p>
      </div>
    </div>
  );
};
