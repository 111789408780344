import { ButtonVariants } from 'components/Button';
import { ButtonLink } from 'components/ButtonLink';
import { Hero } from 'components/Hero';
import { Blocks } from './Blocks';
import { Mission } from './Mission';
import { Onboarding } from './Onboarding';
import styles from './Home.module.css';
import { Section } from 'components/Section';
import { Container, ContainerSizes } from 'components/Container';
import { Helmet } from 'react-helmet';

export const Home = () => {
  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Hero className={styles.hero}>
        <h1>Donate With Confidence</h1>
        <p>
          No more guessing or wondering where your donations go. 100% of your
          donations will go to its intended purpose, and you will SEEIT
          <strong>MOVE</strong> the whole time!
        </p>
        <div className={styles.buttons}>
          <ButtonLink to="/donate" variant={ButtonVariants.Accent}>
            Get Started
          </ButtonLink>
          <ButtonLink
            to="/contact-us"
            variant={ButtonVariants.SecondaryInverse}
          >
            Contact Us
          </ButtonLink>
        </div>
      </Hero>
      <Blocks />
      <Section>
        <Container className={styles.mission} size={ContainerSizes.Small}>
          <Mission />
        </Container>
      </Section>
      <Section>
        <Container className={styles.onboarding}>
          <Onboarding />
        </Container>
      </Section>
    </>
  );
};
