import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { Nomination } from 'models/api/nomination';
import { useFetch } from 'hooks/util/useFetch';
import { ApiError } from 'models/api/error';

export const useNomination = (id?: string) => {
  const [$fetch] = useFetch<Nomination>();
  return useQuery<Nomination, ApiError>(
    ['nominations', id],
    () => $fetch(`/api/nominations/${id as string}`),
    {
      enabled: !!id,
      onError: (error) => {
        toast.error(error.data.message || 'An unexpected error occurred.');
      },
    },
  );
};
