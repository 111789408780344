import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useFetch } from 'hooks/util/useFetch';
import { ApiError } from 'models/api/error';

export const useDeleteUser = () => {
  const [$fetch] = useFetch<void>();
  const queryClient = useQueryClient();
  return useMutation<void, ApiError, string | undefined>(
    (id?: string) => $fetch(`/api/users/${id}`, { method: 'DELETE' }),
    {
      onError: (error) => {
        toast.error(error.data.message || 'An unexpected error occurred.');
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['users']);
      },
    },
  );
};
