import { useMutation, useQueryClient } from 'react-query';
import { useFetch } from 'hooks/util/useFetch';
import { ApiError } from 'models/api/error';
import { UpdateUserRequest, User } from 'models/api/user';
import toast from 'react-hot-toast';

export const useUpdateUser = (id?: string) => {
  const [$fetch] = useFetch<User>();
  const queryClient = useQueryClient();
  return useMutation<User, ApiError, UpdateUserRequest>(
    (values: UpdateUserRequest) =>
      $fetch(`/api/users/${id}`, { body: values, method: 'POST' }),
    {
      onError: (error) => {
        toast.error(error.data.message || 'An unexpected error occurred');
      },
      onSuccess: () => {
        toast.success('User updated successfully');
        queryClient.invalidateQueries(['users']);
      },
    },
  );
};
