import classNames from 'classnames';
import { HTMLProps, PropsWithChildren } from 'react';
import styles from './Button.module.css';

export enum ButtonSizes {
  Medium = 'medium',
  Small = 'small',
}

export enum ButtonVariants {
  Accent = 'accent',
  Primary = 'primary',
  Secondary = 'secondary',
  SecondaryInverse = 'secondary-inverse',
}

interface ButtonProps extends Omit<HTMLProps<HTMLButtonElement>, 'size'> {
  size?: ButtonSizes;
  type?: 'button' | 'reset' | 'submit';
  variant?: ButtonVariants;
}

export const Button = (props: PropsWithChildren<ButtonProps>) => {
  const {
    className,
    size = ButtonSizes.Medium,
    type = 'button',
    variant = ButtonVariants.Primary,
    ...buttonProps
  } = props;

  return (
    <button
      {...buttonProps}
      className={classNames(
        styles.button,
        styles[variant],
        styles[size],
        className,
      )}
      type={type}
    />
  );
};
