import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { ButtonVariants } from 'components/Button';
import styles from 'components/Button/Button.module.css';

interface ButtonLinkProps extends LinkProps {
  to: string;
  variant?: ButtonVariants;
}

export const ButtonLink = (props: PropsWithChildren<ButtonLinkProps>) => {
  const { className, variant = ButtonVariants.Primary, ...linkProps } = props;

  return (
    <Link
      {...linkProps}
      className={classNames(styles.button, styles[variant], className)}
    />
  );
};
