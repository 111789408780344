import { Donation } from 'models/api/donation';

export const getAllocatedAmount = (donation?: Donation): number => {
  return (
    donation?.allocations?.reduce(
      (total, allocation) => (total += allocation.amount),
      0,
    ) || 0
  );
};

export const getAvailableAmount = (donation?: Donation): number => {
  return (donation?.amount || 0) - getAllocatedAmount(donation);
};
