import logoImage from 'assets/images/seeitmove-logo.png';
import styles from './Logo.module.css';

export const Logo = () => {
  return (
    <div className={styles.logo}>
      <img src={logoImage} />
      <span>SeeItMove.org</span>
    </div>
  );
};
