import { ChangeEvent, useEffect, useState } from 'react';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { useNomination } from 'hooks/query/useNomination';
import { useBreakpoints } from 'hooks/util/useBreakpoints';
import { useQueryParam } from 'hooks/util/useQueryParam';
import { List } from './List';
import styles from './Donations.module.css';
import { Button, ButtonVariants } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { formatAmount } from 'utils/format';
import { getAllocatedAmount } from 'utils/nomination';
import { TextField } from 'components/TextField';

export const AdminDonations = () => {
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [filteredId, setFilteredId] = useState<string>('');
  const { isMobile } = useBreakpoints();
  const nominationId = useQueryParam('nominationId');
  const [isShowingFullyAllocated, setIsShowingFullyAllocated] =
    useState<boolean>(false);
  const { data: nomination } = useNomination(nominationId);

  useEffect(() => {
    setIsFilterOpen(!isMobile);
  }, [isMobile]);

  return (
    <Section>
      <Container>
        <header className={styles.header}>
          <h1>Manage Donations</h1>
          {isMobile &&
            (isFilterOpen ? (
              <Button
                key="close"
                onClick={() => setIsFilterOpen(false)}
                variant={ButtonVariants.Secondary}
              >
                Close Filter
              </Button>
            ) : (
              <Button
                key="filter"
                onClick={() => setIsFilterOpen(true)}
                variant={ButtonVariants.Secondary}
              >
                Filter
              </Button>
            ))}
          {isFilterOpen && (
            <div className={styles.filters}>
              <div className={styles['filter-row']}>
                <Checkbox
                  checked={isShowingFullyAllocated}
                  label="Show Fully Allocated"
                  onCheckedChange={(checked) =>
                    setIsShowingFullyAllocated(checked)
                  }
                />
              </div>
              <div className={styles['filter-row']}>
                <TextField
                  label="ID"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilteredId(e.target.value)
                  }
                  value={filteredId}
                />
              </div>
            </div>
          )}
        </header>
        {nomination && (
          <div className={styles.allocating}>
            <p>
              Allocating funds for <strong>{nomination.nominee}</strong> |
              Cause: <strong>{nomination.cause?.name}</strong> | Allocated
              Amount:{' '}
              <strong>{formatAmount(getAllocatedAmount(nomination))}</strong>
            </p>
            <Link to="/admin/donations">
              <FontAwesomeIcon icon={faTimes} />
            </Link>
          </div>
        )}
        <List
          filteredId={filteredId}
          nomination={nomination}
          showFullyAllocated={isShowingFullyAllocated}
        />
      </Container>
    </Section>
  );
};
