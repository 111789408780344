import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren, ReactNode } from 'react';
import styles from './Modal.module.css';

export interface ModalProps {
  actions?: ReactNode[];
  isOpen?: boolean;
  onClose?: () => void;
  title?: string;
}

export const Modal = (props: PropsWithChildren<ModalProps>) => {
  const { actions, children, isOpen, onClose, title } = props;

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className={styles.overlay}
            onClick={onClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
          <motion.div
            className={styles.modal}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.3,
            }}
          >
            <header className={styles.header}>
              <strong>{title}</strong>
              <button className={styles.close} onClick={onClose} type="button">
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </header>
            <div className={styles.content}>{children}</div>
            {actions && actions.length > 0 && (
              <footer className={styles.footer}>
                {actions?.map((action) => action)}
              </footer>
            )}
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};
