import classNames from 'classnames';
import ReactSelect from 'react-select';
import styles from './Select.module.css';

interface SelectOption {
  label: string;
  value: string;
}

interface SelectProps {
  disabled?: boolean;
  error?: string;
  label?: string;
  onChange?: (option: SelectOption | null) => void;
  options?: SelectOption[];
  required?: boolean;
  value?: string;
}

export const Select = (props: SelectProps) => {
  const {
    disabled,
    error,
    label,
    onChange,
    options = [],
    required,
    value,
  } = props;
  const selectOptions = [{ label: '', value: '' }, ...options];

  return (
    <div
      className={classNames(styles.container, {
        [styles.disabled]: disabled,
        [styles.errored]: !!error,
      })}
    >
      {label && (
        <label className={styles.label}>
          {label}
          {required && '*'}
        </label>
      )}
      <ReactSelect
        classNamePrefix="select"
        isDisabled={disabled}
        onChange={onChange}
        options={selectOptions}
        placeholder=""
        value={selectOptions?.find((option) => option.value === value)}
      />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};
