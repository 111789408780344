import React from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import 'styles/tokens.css';
import 'styles/reset.css';
import 'styles/global.css';
import App from 'app';
// import { ThemeProvider } from 'providers/ThemeProvider';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={client}>
        {/* <ThemeProvider> */}
        <App />
        <Toaster />
        {/* </ThemeProvider> */}
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
