import { Container } from 'components/Container';
import { Helmet } from 'react-helmet';
import { Link, Outlet } from 'react-router-dom';
import styles from './Admin.module.css';

export const Admin = () => {
  return (
    <>
      <Helmet>
        <title>Admin</title>
      </Helmet>
      <Container>
        <nav className={styles.nav}>
          <ul>
            <li>
              <Link to="/admin">Dashboard</Link>
            </li>
            <li>
              <Link to="/admin/causes">Causes</Link>
            </li>
            <li>
              <Link to="/admin/nominations">Nominations</Link>
            </li>
            <li>
              <Link to="/admin/donations">Donations</Link>
            </li>
            <li>
              <Link to="/admin/users">Users</Link>
            </li>
          </ul>
        </nav>
      </Container>
      <Outlet />
    </>
  );
};
