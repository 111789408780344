import { useFormik } from 'formik';
import { ChangeEvent, useEffect } from 'react';
import { Button } from 'components/Button';
import { Modal, ModalProps } from 'components/Modal';
import { TextField } from 'components/TextField';
import { useCreateAllocation } from 'hooks/mutation/useCreateAllocation';
import { useNomination } from 'hooks/query/useNomination';
import { Donation } from 'models/api/donation';
import { getAvailableAmount } from 'utils/donation';
import styles from './AllocationModal.module.css';
import { formatAmount } from 'utils/format';
import { getAllocatedAmount } from 'utils/nomination';

interface AllocationModalProps extends ModalProps {
  id?: string;
  donation?: Donation;
}

export const AllocationModal = (props: AllocationModalProps) => {
  const { id, donation, ...modalProps } = props;
  const { data: nomination } = useNomination(id);
  const { mutateAsync: createAllocation } = useCreateAllocation();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: getAvailableAmount(donation) / 100,
    },
    onSubmit: async (values) => {
      if (!donation || !nomination) return false;

      await createAllocation({
        ...values,
        donationId: donation.id,
        nominationId: nomination.id,
      });

      modalProps.onClose?.();
    },
  });

  useEffect(() => {
    console.log('donation', donation);
    console.log('nomination', nomination);
  }, [donation, nomination]);

  return (
    <Modal
      isOpen={!!donation && !!nomination}
      title="Allocate Funds"
      {...modalProps}
    >
      <div className={styles.heading}>
        <p>
          Donation Available Amount:{' '}
          <strong>{formatAmount(getAvailableAmount(donation))}</strong>
        </p>
        <p>
          Nomination Allocated Amount:{' '}
          <strong>{formatAmount(getAllocatedAmount(nomination))}</strong>
        </p>
      </div>
      <form className={styles.form} noValidate onSubmit={formik.handleSubmit}>
        <TextField
          error={formik.errors.amount}
          label="Amount"
          name="amount"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            formik.setFieldValue('amount', Number(e.target.value))
          }
          required
          type="number"
          value={formik.values.amount}
        />
        <Button type="submit">Allocate Funds</Button>
      </form>
    </Modal>
  );
};
