import { useMutation } from 'react-query';
import { useFetch } from 'hooks/util/useFetch';
import toast from 'react-hot-toast';
import {
  AuthForgotPasswordRequest,
  AuthForgotPasswordResponse,
} from 'models/api/auth';
import { ApiError } from 'models/api/error';

export const useAuthForgotPassword = () => {
  const [$fetch] = useFetch<AuthForgotPasswordResponse>();
  return useMutation<
    AuthForgotPasswordResponse,
    ApiError,
    AuthForgotPasswordRequest
  >(
    (values) =>
      $fetch('/api/auth/forgot-password', {
        body: values,
        method: 'POST',
      }),
    {
      onError: (error) => {
        toast.error(error.data.message || 'An unexpected error occurred.');
      },
      onSuccess: (data) => {
        toast.success(data.message);
      },
    },
  );
};
