import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useFetch } from 'hooks/util/useFetch';
import { User } from 'models/api/user';
import { ApiError } from 'models/api/error';

export const useAuth = () => {
  const [$fetch] = useFetch<User>();
  return useQuery<User, ApiError>(['auth'], () => $fetch('/api/auth'), {
    onError: (error) => {
      toast.error(error.data.message || 'An unexpected error occurred.');
    },
    retry: false,
    staleTime: Infinity,
  });
};
